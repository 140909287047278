import { api } from '../../../api/axios-base'
export const apiMixin = {
  methods: {
    /**
     * Obtiene todos los elementos
     * @param {string} moduleName Nombre del modulo
     * @param {string} objName Nombre del objeto a obtener
     * @returns {Array} Resultados devueltos por el backend
     */
    async fetchResultsAll(moduleName, objName) {
      try {
        const response = await api.get(`/${moduleName}/api/${objName}/`)
        const responseData = await response.data
        if (responseData.e) {
          return { error: `No se pudieron obtener ${objName}` }
        }
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener' + objName, error);
      }
    },
    /**
     * Obtiene los elementos filtrados por institucion educativa
     * @param {string} moduleName Nombre del modulo
     * @param {string} objName Nombre del objeto a obtener
     * @param {number} institutionId id de la institucion educativa
     * @returns {array} Resultados devueltos por el backend filtrados por institucion educativa
     */
    async fetchResultsByEI(moduleName, objName, institutionId) {
      try {
        const response = await api.get(`/${moduleName}/api/${objName}-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        if (responseData.e) {
          return { error: `No se pudieron obtener ${objName}` }
        }
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener' + objName + ' por institucion educativa.', error);
      }
    },
    /**
     * Obtiene el elemento correspondiente al id indicado
     * @param {string} moduleName Nombre del modulo
     * @param {string} objName Nombre del objeto
     * @param {number} objId Id del objeto
     * @returns {object} Objeto correspondiente al id indicado
     */
    async fetchObjectById(moduleName, objName, objId) {
      try {
        const response = await api.get(`/${moduleName}/api/${objName}/${objId}/`)
        const responseData = await response.data
        if (responseData.e) {
          return { error: `No se pudieron obtener ${objName}` }
        }
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener' + objName, error);
      }
    },
    async fetchResultsWithParams(moduleName, objName, params) {
      try {
        const response = await api.get(`/${moduleName}/api/${objName}/`,
        {
          params: params
        })
        const responseData = await response.data
        if (responseData.e) {
          return { error: `No se pudieron obtener ${objName} con parametros: ${params}` }
        }
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener' + objName, error);
      }
    },
    /**
     * 
     * @param {string} url Ruta que se va a ejecutar
     * @param {object} objData Objeto que se para a la ruta
     * @param {string} action Texto de la accion a realizar. Por defecto 'crear'
     * @returns {object} Objeto devuelto por el backend
     */
    async postObj(url, objData, action = 'crear') {
      try {
        const response = await api.post(url, objData)
        const responseData = await response.data
        // console.log('Respuesta url - ', url, responseData)
        if (responseData.e) {
          return { error: `No se pudo ${action} el objeto: ${responseData.e.join(', ')}` }
        }
        return responseData
      } catch (error) {
        //this.errors.push('Error al intentar ' + action + ' objeto')
        console.error('Error al intentar ' + action + ' objeto.', error);
      }
    },

// ************************** Metodos Paquito ********************************************** //
    /**
     * @param {string} url ruta ha ejecutar
     * @param {Object}  data datos a mandar al back 
     */
     async post(url, data){
      try {
        const response = await api.post(url, data)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Ocurrio un error al intenter realizar la petición POST', error);
      }
    },
    /**
     * @param {string} url ruta ha ejecutar
     */
    async get(url){
      try {
        const response = await api.get(url)
        const responseData = await response.data
        return responseData
      } catch (error) {
        // console.log('Ocurrio un error al intenter realizar la petición GET', error)
      }
    },
    /**
     * @param {string} url ruta a ejecutar
     * @param {number} id identificador del elemento a elminar
     */
    async delete(url, id){
      try {
        const response = await api.delete(`${url}/${id}`)
        const responseData = await response.data
        if(response.status == 200){
         // console.log('La acción fue realizada correctamente'+ response.data) 
         return responseData;
        }else{
          // console.log('Ocurrio un error')
        }
      } catch (error) {
        // console.log('Ocurrio un error al intenter realizar la petición DELETE', error)
      }
    },
    /**
     * @param {string} url ruta a ejecutar
     * @param {number} id elemento especifico a actualizar
     * @param {Object} data datos que se mandan al back
     */
    async put(url, id, data){
      try {
        const response = await api.put(`${url}/${id}`, data)
        const responseData = await response.data
        if(response.status == 200){
          // console.log("El acción fue realizada correctamente" 
          // + response.data)
          return responseData
        }
      } catch (error) {
        // console.log('Ocurrio un error al intenter realizar la petición PUT', error)
      }  
    }
  }
}