<template>
  <section>
    <!-- Divider at the top -->
    <v-divider v-if="showDivider && dividerPosition === 'top'" class="my-8"></v-divider>

    <v-row class="mb-10">
      <v-col cols="12" class="d-flex align-center">
        <!-- Title -->
        <h3 class="purple--text flex-grow-1">{{ title }}</h3>

        <!-- Checkbox -->
        <v-checkbox v-if="showCheckbox" v-model="localIsChecked" :label="checkboxLabel" />
      </v-col>
    </v-row>

    <!-- Alert -->
    <v-alert v-if="showAlert" :type="alertType" dismissible>
      {{ alertMessage }}
    </v-alert>

    <!-- Divider at the bottom -->
    <v-divider v-if="showDivider && dividerPosition === 'bottom'" class="my-8"></v-divider>

    <!-- Additional spacing if top divider is used -->
    <div v-if="showDivider && dividerPosition === 'top'" class="mb-6"></div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    showDivider: {
      type: Boolean,
      default: false
    },
    showAlert: {
      type: Boolean,
      default: false
    },
    dividerPosition: {
      type: String,
      default: 'top',
      validator: (value) => ['top', 'bottom'].includes(value)
    },
    showCheckbox: {
      type: Boolean,
      default: false
    },
    checkboxLabel: {
      type: String,
      default: 'Option'
    },
    alertMessage: {
      type: String,
      default: 'The selected option will take effect immediately.'
    },
    isChecked: {
      type: Boolean,
      default: false
    },
    alertType: {
      type: String,
      default: 'info',
      validator: (value) => ['info', 'error', 'warning', 'success'].includes(value)
    },
    isNewMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localIsChecked: this.isChecked
    };
  },
  
  watch: {
    localIsChecked(newValue) {
      this.$emit('update:isChecked', newValue);
    }
  }
};
</script>
