import { getItem, postItem, putItem } from "../../../api/api-methods";


export const fetchPostProduct = async ({ product, taxes, options, images, documents }) => {
  try {
    const URL = `/app-productos/product`;
    const responseData = await postItem(URL, {
      ...product,
      documentos: documents,
      imagenes: images,
      impuesto: taxes,
      opcion_producto: options,
    });
    return responseData;
  } catch (error) {
    console.error('Error al intentar crear producto:', error);
  }
};
export const fetchGetInventoryByProduct = async (product, productOption, institution) => {
  try {
    const URL = `/app-inventarios/filters/inventario?producto=${product}&opcion_producto=${productOption}&institucion_educativa=${institution}&estatus_sistema=true`;
    const responseData = await getItem(URL);
    return responseData;
  } catch (error) {
    console.error('Error al intentar crear producto:', error);
  }
};

export const fetchPutProduct = async ({ product, taxes, options, images, documents }) => {
  try {
    const URL = `/app-productos/product/${product.id}`;
    const responseData = await putItem(URL, {
      ...product,
      categoria_producto: product.categoria_producto.id,
      documentos: documents,
      imagenes: images,
      impuesto: taxes,
      opcion_producto: options,
      precios: product.precios.map(e => e.id),
      subcategoria_producto: product.subcategoria_producto.id,
    });
    return responseData;
  } catch (error) {
    console.error('Error al intentar actualizar producto:', error);
  }
};


export const fetchGetPriceProduct = async (price) => {
  try {
    const URL = `/app-productos/precio-distribuidor/${price}`;
    const responseData = await getItem(URL);
    return responseData;
  } catch (error) {
    console.error('Error al intentar actualizar producto:', error);
  }
};