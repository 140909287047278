//import { api } from '../api/axios-base'
import { postItem, deleteItem } from "../../../api/api-methods";

export const fileUtilsMixin = {
  data() {
    return {
      errors: [],
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
    }
  },
  methods: {
    async uploadFile(fileObj) {
      try {
        const formData = new FormData()
        formData.append('archivos', fileObj)
        const responseData = await postItem('/app-personas/helpers/guardar-archivos',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        if ( responseData.e ) {
          this.errors.push('No se pudo subir el archivo: ' + responseData.e.join(','))
          return
        }
        // console.log('Respuesta subir archivo:', responseData);
        return responseData
      } catch (error) {
        console.error('Error al intentar subir archivo');
      }
    },
    async postReceiptDoc(docObj) {
      try {
        const responseData = await postItem('/app-administracion/documento-comprobante', docObj)
        if ( responseData.e ) {
          this.errors.push('No se pudo crear el comprobante: ' + responseData.e.join(','))
          return
        }
        return responseData
      } catch (error) {
        console.error('Error al intentar crear documento comprobante');
      }
    },
    async deleteReceipt(documentId) {
      try {
        const responseData = await deleteItem(`/app-administracion/documento-comprobante/${documentId}`)
        // console.log('Respuesta eliminar documento comprobante:', responseData);
        if ( responseData.e ) {
          this.errors.push('No se pudo eliminar el comprobante: ' + responseData.e.join(','))
          return
        }
        return responseData
      } catch (error) {
        console.error('Error al intentar eliminar documento comprobante.', error);
      }
    },
    async deleteReceiptPermanently(receiptId) {
      try {
        const responseData = await postItem('/personas/delete-documento-comprobante-permantente', {
          id_documento_comprobante: receiptId
        })
        // console.log('Respuesta eliminar documento comprobante permanentemente:', responseData);
        if ( responseData.e ) {
          this.errors.push('No se pudo subir el archivo: ' + responseData.e.join(','))
          return
        }
        return responseData
      } catch (error) {
        console.error('Error al intentar eliminar documento comprobante permanentemente.', error);
      }
    },
    // async deleteDocumentPermanently(documentId) {
    //   try {
    //     const response = await api.post('/personas/delete-documento-permanente', {
    //       id_documento: documentId
    //     })
    //     const responseData = await response.data
    //     console.log('Respuesta eliminar documento permanentemente:', responseData);
    //     if (responseData.e) {
    //       this.errors.push('Error al eliminar documento permanentemente: ' + responseData.e.join(', '))
    //     }
    //   } catch (error) {
    //     this.errors.push('Error al intentar eliminar documento permanentemente')
    //     console.error('Error al intentar eliminar documento permanentemente.', error);
    //   }
    // }
  }
}