<template>
  <v-row
    align="center"
    :justify="justifySmall"
    class="mt-10"
  >
    <v-col
      cols="12"
      md="4"
      lg="4"
      xl="4"
    >
      <v-btn
        outlined
        tile
        block
        :small="small"
        @click="cancel"
      >
        Cancelar
      </v-btn>
    </v-col>
    <v-col
      cols="12"
      md="4"
      lg="4"
      xl="4">
      <v-btn
        color="primary"
        tile
        block
        :disabled="disableAccept"
        :small="small"
        :loading="loading"
        @click="accept"
      >
        {{ acceptBtnLabel }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    small: {
      type: Boolean,
      default: false
    },
    justify: {
      type: String,
      default: null
    },
    disableAccept: {
      type: Boolean,
      default: false
    },
    acceptBtnLabel: {
      type: String,
      default: 'Guardar'
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    justifySmall() {
      return this.justify !== null 
        ? this.justify 
        : this.small 
          ? 'start' : 'center' 
    }
  },
  methods: {
    accept() {
      this.$emit('accept')
    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>

<style>

</style>